<template>
  <div id="GalleryMain">
    <Navbar>
      <span> {{ $t('navBarMoomall.gallery') }}</span>
    </Navbar>
    <v-main>
      <v-container>
        <BaseGallery />
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'GalleryMain',
}
</script>

<style scoped></style>
